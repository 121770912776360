<div class="blogBody">
  <div>
    <div class="row rowNoM backgroundImg">
      <div class="col-md-2 col-sm-2"></div>
      <div class="col-md-6 col-sm-6">
        <img class="imageMargin" src="../../assets/img/GMap.jpg" />
      </div>
      <div class="col-md-4 col-sm-4 tableofContent">
        <h3>Table of Contents</h3>
        <ol class="listOL">
          <li>
            <a class="TableLink" href="#create">Create Angular CLI App</a>
          </li>
          <li>
            <a class="TableLink" href="#install">Install Google Maps types</a>
          </li>
          <li>
            <a class="TableLink" href="#cdn">Google Maps JavaScript CDN</a>
          </li>
          <li>
            <a class="TableLink" href="#cons">Constructing the Map</a>
          </li>
          <li>
            <a class="TableLink" href="#dir">Directions and Places auto-complete</a>
          </li>
          <li>
            <a class="TableLink" href="#geo">Geolocation</a>
          </li>
          <li>
            <a class="TableLink" href="#conclusion">Conclusion</a>
          </li>
        </ol>
      </div>
    </div>
    <div class="buttonBanner">
      <a href="https://github.com/sibirajr/GMap-Navigation" target="_blank">
        <button type="button" class="btn btn-success btmBamA">Code</button>
      </a>
      <button type="button" class="btn btn-success btmBamB" [routerLink]="['GMaps']">Demo</button>
    </div>
  </div>
  <div class="blogMainBody">
    <div class=" col-md-1"></div>
    <div class=" col-md-10 blogBodyContent">
      <p>
        Ever wondered how these guys like Swiggy,Ola etc are utilizing google maps? Lets dive into and learn one of
        the google's
        API (i.e.)Google Maps API and learn how to integrate with in Angular CLI app.
      </p>
      <p>
        To begin with the Google's API the pre-requisite is to acquire a API key from google.You can get one by
        following steps
        in <a href="https://developers.google.com/maps/documentation/javascript/get-api-key" target="_blank">official
          site</a>.
      </p>
      <h2 id="create">
        Create Angular CLI App
      </h2>
      <p>
        Now as usual lets create a CLI app.
      </p>
      <p>
        Lets consider you don't have CLI installed, then go ahead and install CLI using the command globally.
      </p>
      <div class="preCode">
        npm install @angular/cli -g
      </div>
      <p>
        Now lets create a new CLI app named
        <span class="highlightText">G-Map</span>.
      </p>
      <div class="preCode">
        ng new G-Map
      </div>
      <p>
        This command will create a app in the folder G-Map, now lets
        <span class="highlightText">cd</span> into the folder and run the command
      </p>
      <div class="preCode">
        ng serve
      </div>
      <p>
        This will run the angular app in port
        <span class="highlightText">4200</span> by default. So you can launch the app by browsing to
        <span class="highlightText">localhost:4200</span>. It will launch a default Angular cli app.
      </p>
      <p>
        Now lets move on and create a component for Google map API using CLI.
      </p>
      <p>
        Let’s create the component.
      </p>
      <div class="preCode">
        ng generate component g-map
      </div>
      <p>This will create the following files in our
        <span class="highlightText">src/app/g-map</span> folder:</p>
      <ol>
        <li>g-map.component.html</li>
        <li>g-map.component.ts</li>
        <li>g-map.component.css</li>
        <li>g-map.component.spec.ts</li>
      </ol>
      <h2 id="install">
        Install Google Maps types
      </h2>
      <p>
        To get <span class="highlightText">Typescript support</span> for Maps API install types supporting Google
        Maps. Run the following command to install
        types.
      </p>
      <div class="preCode">
        npm install -- save @types/googlemaps
      </div>

      <h2 id="cdn">
        Google Maps JavaScript CDN
      </h2>
      <p>
        Link the Google Maps JavaScript CDN as a script tag inside <span class="highlightText">Index.html</span>
      </p>
      <div class="panel panel-default">
        <div class="panel-heading codePanelHTML">
          Index.html
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustomHTML">
<code>
&#60;script src="https://maps.googleapis.com/maps/api/js?key=YOUR API KEY" type="text/javascript"&#62; &#60;script&#62;
</code>
            </pre>
        </div>
      </div>
      <h2 id="cons">
        Constructing the Map
      </h2>

      <div class="panel panel-default">
        <div class="panel-heading codePanelHTML">
          g-map.component.html
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustomHTML">
<code>
&#60;div class="map" #googleMap id="googleMap"&#62; &#60;div&#62;
</code>
            </pre>
        </div>
      </div>
      <p>
        This this tag with id'ed as <span class="highlightText">googleMap</span> will be used as a reference to G
        Map
        features using @ViewChild which will be
        discussed below. @ViewChild will create a direct link between <span class="highlightText">gmapElement</span>
        and the div tag.
      </p>
      <p>
        Now write some typescript code to make the maps work
      </p>

      <div class="panel panel-default">
        <div class="panel-heading codePanel">
          g-map.component.ts
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustom">
<code>
import &#123;  &#125; from '@types/googlemaps;<br>
import &#123; Component, OnInit, ViewChild , &#125; from '@angular/core;<br>
@Component(&#123;<br>
selector: 'g-map',<br>
templateUrl: 'g-map.component.html',<br>
styleUrls: ['./g-map.component.css']<br>
&#125;)<br>
export class GMapsExp &#123;<br>
@ViewChild('googleMap') private gmapElement: any;<br>
private map: google.maps.Map;

ngOnInit() &#123;

   var mapProp = &#123;

     center: new google.maps.LatLng(13.0827, 80.2707),

     zoom: 14,

     mapTypeId: google.maps.MapTypeId.ROADMAP

     // mapTypeId: google.maps.MapTypeId.HYBRID

     // mapTypeId: google.maps.MapTypeId.SATELLITE

     // mapTypeId: google.maps.MapTypeId.TERRAIN

     &#125;;

   this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

   var marker = new google.maps.Marker(&#123; position: mapProp.center&#125;);

   marker.setMap(this.map);

  &#125;

&#125;

</code>
            </pre>
        </div>
      </div>

      <p>
        Now breaking apart, Import the google types that we have installed in to the component you are writing
        these codes to get rid of some var and any.
      </p>
      <p>
        In oninit we are defining the parameters for showing the Google maps in the page which can be self
        understood. Passing the values to the <span class="highlightText">google.maps.Map</span> will return a Map
        object where we are capturing it for
        future use like placing a marker etc.
      </p>
      <p>
        And inside oninit there a typical example for a marker on the map using <span
          class="highlightText">google.maps.Marker</span>
        where this constructor will return a marker object and we are using it to assign to the map object using
        <span class="highlightText">setMap</span> function to display marker.
      </p>
      <p>
        Let's write some CSS
      </p>
      <div class="panel panel-default">
        <div class="panel-heading codePanelCSS">
          g-map.component.css
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustomCSS">
<code>
.map &#123;
  height: 50vh;
  width: 50vw;
&#125;
</code>
            </pre>
        </div>
      </div>
      <p>
        Almost done, now ngserve and you can see a basic road map with marker a at chennai central :)
      </p>
      <p>
        Let's proceed and explore some advanced features in API
      </p>
      <h2 id="dir">
        Directions and Places auto-complete.
      </h2>
      <p>
        To work with places an addition CDN has to be used a like one used before in Index.html.
      </p>

      <div class="panel panel-default">
        <div class="panel-heading codePanelHTML">
          Index.html
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustomHTML">
<code>
&#60;script src="https://maps.googleapis.com/maps/api/js?key=YOUR API KEY &libraries=places"&#62; &#60;script&#62;
</code>
            </pre>
        </div>
      </div>

      <p>
        Now add codes to the existing code to introduce places features.
      </p>
      <div class="panel panel-default">
        <div class="panel-heading codePanelHTML">
          g-map.component.html
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustomHTML">
<code>
&#60;input id="autocompleteFrom" placeholder="Enter from city" type="text" /&#62;

&#60;input id="autocompleteTo"  placeholder="Enter to city" type="text" /&#62;

&#60;button class="col-xs-6 col-sm-6 col-md-1 BtN" id="searchButton" mat-button (click)="search()"&#62;

    Route

&#60;/button>

&#60;div class="map" #googleMap id="googleMap"&#62; &#60;div&#62;
</code>
            </pre>
        </div>
      </div>
      <p>
        Two inputs were introduced to get from and to places respectively,Now we have to populate input with places
        taking the help of API.
      </p>
      <div class="panel panel-default">
        <div class="panel-heading codePanel">
          g-map.component.ts
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustom">
<code>
private directionsService: any;

private directionsDisplay: any;

private autocompleteFrom: any;

private autocompleteTo: any;

private countryRestrict: any;

ngOnInit() &#123;

//Keep the existing codes.

this.countryRestrict = &#123; country: 'IN' &#125;;

this.directionsService = new google.maps.DirectionsService();

this.directionsDisplay = new google.maps.DirectionsRenderer();

this.directionsDisplay.setMap(this.map);

/* this.directionsDisplay.setPanel(

document.getElementById('CompleteDirection')  //If you want to display complete instructions.

); */

this.autocompleteFrom = new google.maps.places.Autocomplete(

&#60;HTMLInputElement&#62;document.getElementById('autocompleteFrom'),

&#123;

  types: ['(cities)'],

  componentRestrictions: this.countryRestrict

&#125;
);
this.autocompleteTo = new google.maps.places.Autocomplete(

&#60;HTMLInputElement&#62;document.getElementById('autocompleteTo'),

&#123;

  types: ['(cities)'],

  componentRestrictions: this.countryRestrict

&#125;

);

this.places = new google.maps.places.PlacesService(this.map);

&#125;

</code>
    </pre>
        </div>
      </div>
      <p>
        Here we are utilizing <span class="highlightText">DirectionsService and DirectionsRenderer</span> to
        display directions and we are binding the map to the these services.
      </p>
      <p>
        Then we have to specify the HTML elements(inputs) where we are gonna show autocomplete using
        <span class="highlightText">Places.Autocomplete</span>.Inside the autocomplete constructor we are
        including the types of places like cities,hotels etc.<span class="highlightText"> componentRestrictions
        </span>
        is used to localize where it will increase the performance and eventually reduce data.
      </p>
      <div class="panel panel-default">
        <div class="panel-heading codePanel">
          g-map.component.ts
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustom">
<code>

private search() &#123;

var place = this.autocompleteFrom.getPlace();

var placeTo = this.autocompleteTo.getPlace();

if (place && place.geometry) &#123;

  this.map.panTo(place.geometry.location);

  this.map.setZoom(15);

  var request = &#123;

    origin: place.formatted_address,

    destination: placeTo.formatted_address,

    optimizeWaypoints: true,

    travelMode: 'DRIVING'

  &#125;

  this.directionsService.route(

    request,

    function(response, status) &#123;

      if (status === 'OK') &#123;

        this.directionsDisplay.setDirections(response);

      &#125;

    &#125;.bind(this)

  );

&#125;

&#125;

</code>
      </pre>
        </div>
      </div>
      <p>
        It is the method which will be fired on button click to Route. Here in this method we are passing from and
        to location to the <span class="highlightText">DirectionsService </span>and API takes care the rest. The
        are some parameters which are self explanatory. The part i struck a bit is <span
          class="highlightText">.bind(this)
        </span> and we are gonna speak a little bit about that.
      </p>
      <p>
        Now try removing .bind and run the code. There will be a error indicating directionsDisplay is undefined
        and on inspection of <span class="highlightText">this
        </span> you will be unable to see the class variables.
      </p>
      <p>
        Lets speak about the behavior of function expression and Arrow function case.<span class="highlightText">In
          the function expression case, this refers to the object that was created inside the createObject. In the
          arrow function case, this refers to this of createObject itself,</span> which explains the scenario.
      </p>
      <h2 id="geo">
        Geolocation
      </h2>
      <p>
        Back to Maps API, one of the mandatory option is Find me (i.e.)finding the location of a user.
      </p>
      <div class="panel panel-default">
        <div class="panel-heading codePanel">
          g-map.component.ts
        </div>
        <div class="panel-body bodyCode">
          <pre class="preCustom">
<code>

private FindMe() &#123;

if (navigator.geolocation) &#123;

  navigator.geolocation.getCurrentPosition(

    function(position) &#123;

      var pos = &#123;

        lat: position.coords.latitude,

        lng: position.coords.longitude

      &#125;

      this.lat = position.coords.latitude;

      this.lng = position.coords.longitude;

      this.map.setCenter(pos);

      var marker = new google.maps.Marker(&#123; position: pos &#125;);

      marker.setMap(this.map);

    &#125;.bind(this)

  );

&#125;

&#125;

</code>
      </pre>
        </div>
      </div>
      <p>
        To determine a users location we are using <span class="highlightText">W3W's navigator.geolocation</span>
        property. The user should allow site to access location else it will not work, where the developer should
        take care of.
      </p>
      <h2 id="conclusion">
        Conclusion
      </h2>
      <p>
        Its becoming lengthy, i have showed you only the tip of this iceberg. Learn more of this Maps API in <a
          href="https://developers.google.com/maps/documentation/javascript" target="_blank">official
          site</a> where you can find some more impressive features like waypoints, styling etc.
      </p>
      <p>
        Happy Coding!
      </p>
    </div>
  </div>
