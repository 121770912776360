import { Component } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  images: string[] = [
    '../../assets/img/cisco-appreciation/1.png',
    '../../assets/img/cisco-appreciation/2.png',
    '../../assets/img/cisco-appreciation/3.png',
    '../../assets/img/cisco-appreciation/4.png',
    '../../assets/img/cisco-appreciation/5.png',
    '../../assets/img/cisco-appreciation/6.png',
    '../../assets/img/cisco-appreciation/7.png',
    '../../assets/img/cisco-appreciation/8.png',
    '../../assets/img/cisco-appreciation/9.png',
    '../../assets/img/cisco-appreciation/10.jpeg',
    '../../assets/img/cisco-appreciation/11.jpeg',
  ];

  currentIndex: number = 0;

  prev(): void {
    this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.images.length - 1;
    this.updateCarousel();
  }

  next(): void {
    this.currentIndex = (this.currentIndex < this.images.length - 1) ? this.currentIndex + 1 : 0;
    this.updateCarousel();
  }

  goToSlide(index: number): void {
    this.currentIndex = index;
    this.updateCarousel();
  }

  updateCarousel(): void {
    const offset = -this.currentIndex * 100;
    document.querySelector('.carousel-images')!.setAttribute('style', `transform: translateX(${offset}%)`);
  }
}
