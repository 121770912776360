import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-home',
  templateUrl: './site-home.component.html',
  styleUrls: ['./site-home.component.scss']
})
export class SiteHomeComponent implements OnInit {

  public menus: boolean = false;
  // constructor(public homeService: HomeService) {}

  public ngOnInit() {
    console.log("Home");
  }

  public routeToAbout() { }

  public dialogBlogClick() {
    //this.homeService.showDialogBlog = true;
  }

  public dialogBlogGMapClick() {
    //this.homeService.showDialogGMapBlog = true;
  }

}
