<html>

<body>
  <nav class="navbar navbar-fixed-top navBarSt">
    <div class="navbar-header col-12 navbarText">
      <a class="aLink" href="#sibi">sibiraj rajendran</a>
      <span class="navbar-toggler matIcon" data-toggle="collapse" aria-expanded="true" [matMenuTriggerFor]="menu">
        <i class="material-icons">menu</i>
      </span>
    </div>
    <mat-menu #menu>
      <a class="matMenuNew" mat-menu-item> </a>
      <a class="matMenuNew" mat-menu-item href="#résumé">Résumé</a>
      <a class="matMenuNew" mat-menu-item href="#work">work + fun</a>
      <a class="matMenuNew" mat-menu-item [routerLink]="['GMaps']">G-Map API</a>
      <a class="matMenuNew" mat-menu-item href="#about">about</a>
      <a class="matMenuNew" mat-menu-item target="_blank"
        href="https://hackathon2k18-9873f.firebaseapp.com/">Hackathon2k18</a>
    </mat-menu>
  </nav>
  <div id="sibi" ngsRevealSet [ngsSelector]="'.item'">
    <div class="imgMain hidden-sm hidden-xs item"></div>
    <img class="hidden-md hidden-lg item" style="width: 100%" src="../../assets/img/programmer-1.jpg" />
  </div>
  <div id="about" class="bgClr">
    <p class="PColor">HELLO, WORLD! I'M A DEVELOPER.</p>
    <div class="row rowOverR">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <div class="panel panel-default">
          <div class="panel-heading PHeadCol"></div>
          <div class="panel-body PContentCol" ngsRevealSet [ngsSelector]="'.item'">
            <div class="heading">> Sibi.origin</div>
            <div class="answer item">=> [Rasipuram Namakkal T.N]</div>
            <div class="heading">> Sibi.education</div>
            <div class="answer item">=> [Bachelor of Engineering]</div>
            <div class="heading">> Sibi.major</div>
            <div class="answer item">=> [Computer Science]</div>
            <div class="heading">> Sibi.graduation</div>
            <div class="answer item">=> [2016]</div>
            <div class="heading">> Sibi.YOE</div>
            <div class="answer item">=> 7.5</div>
            <div class="heading">> Sibi.interest</div>
            <div class="answer item">
              => [Code, Music, Youtube, Astronomy, GOT, Coffee]
            </div>
            <div class="heading">> Sibi.workExperience</div>
            <div class="answer item">
              => Latest Work Experience
              <br />
              <span class="answer item">&#123;</span>
              <br />
              <span class="inner item"> [company: "CISCO"],</span>
              <br />
              <span class="inner item">[role: "Software Engineer"],</span>
              <br />
              <span class="inner item"> [location: "Chennai"]</span>
              <br />
              <span class="inner item"> [startDate: "Nov 2021"]</span>
              <br />
              <span class="answer item">&#125;</span>
            </div>
            <div class="heading">> Sibi.email</div>
            <div class="answer item PLinkCol">
              =>
              <a href="mailto:mail@sibiraj.co.in">["mail&#64;sibiraj.co.in"]</a>
            </div>
          </div>
        </div>
        <div class="divider">---</div>
      </div>
    </div>
  </div>
  <div id="résumé" class="ResBgCol">
    <p class="PResCol">
      Résumé
      <button class="resumeDownload"><a href="../../assets/Sibiraj SPR 2024.pdf" download="Sibiraj-Rajendran.pdf"><i
            class="fas fa-download"></i></a></button>
    </p>

    <div class="row rowOverR">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <p class="schoolDetails">work experience</p>
        <div onMouseOver="this.className= 'collegeDetailsOut'" onMouseOut="this.className= 'collegeDetails'" style="
              line-height: 34px;
              font-size: 19px;
              font-family: 'Karla', sans-serif;
              letter-spacing: 0.1em;
              filter: grayscale(100%);
            ">
          <div class="col-md-1 hidden-sm hidden-xs" ngsRevealSet [ngsSelector]="'.item'">
            <img class="item imageFit" src="../../assets/img/cisco-logo.png" />
          </div>
          <div class="row col-md-11 col-sm-12">
            <div class="row rowOverR col-md-12 col-sm-12">
              <a target="_blank" class="col-md-8 CollLink" href="https://www.cisco.com/">CISCO</a>
              <span class="col-md-4 years">November 2021 - Present</span>
            </div>
            <div style="margin-right: 0px; margin-left: 0px" class="row col-md-12 col-sm-12">
              <span class="col-md-8 boldText">Software Engineer</span>
              <span class="col-md-4 years">Chennai</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="work" class="row RCourse">
      <div class="col-md-12 col-sm-12 WorkDetaisl">
        <div class="col-md-3 col-sm-2"></div>
        <div class="col-md-7 col-sm-10" ngsRevealSet [ngsSelector]="'.item'">
          <div class="item ContentPara">
            <ul class="listCourse" ngsRevealSet [ngsSelector]="'.item'">

              <li class="item">
                Develop the Webex Wholesale UI and backend solutions using Angular, React, HTML, SCSS, NgRx,
                and Java.
              </li>
              <li class="item">
                Provide strategic technical guidance, involve in High-level and Low-level
                designing, and foster cross-team collaboration, driving innovations
                and optimizing planning while supporting the team’s work-life
                balance.
              </li>
              <li class="item">
                Utilize Jest for unit testing, Cypress for end-to-end testing,
                and Git for version control
              </li>
              <li class="item">
                Actively engage in code reviews, DORs and SPIKEs to
                facilitate efficient planning and ensure adherence to agile
                development practices.

              </li>

            </ul>
          </div>
          <span class="skill item">Angular 11, 12, 15</span>
          <span class="skill item">React</span>
          <span class="skill item">Typescript</span>
          <span class="skill item">HTML</span>
          <span class="skill item">SASS, CSS</span>
          <span class="skill item">Javascript</span>
          <span class="skill item">Rxjs</span>
          <span class="skill item">Ngrx</span>
          <span class="skill item">Cypress, Selenium</span>
          <span class="skill item">Jest, Jasmine</span>
          <span class="skill item">Java</span>
          <span class="skill edu item">Team Management</span>
          <span class="skill edu item">DOR's</span>
          <span class="skill edu item">SPIKE</span>
          <span class="skill edu item">High/Low level designing</span>
          <span class="skill edu item">Code review</span>
          <span class="skill edu item">GIT</span>
        </div>
      </div>
    </div>

    <div class="row rowOverR">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <div onMouseOver="this.className= 'collegeDetailsOut'" onMouseOut="this.className= 'collegeDetails'" style="
              line-height: 34px;
              font-size: 19px;
              font-family: 'Karla', sans-serif;
              letter-spacing: 0.1em;
              filter: grayscale(100%);
            ">
          <div class="col-md-1 hidden-sm hidden-xs" ngsRevealSet [ngsSelector]="'.item'">
            <img class="item imageFit" src="../../assets/img/sg.png" />
          </div>
          <div class="row col-md-11 col-sm-12">
            <div class="row rowOverR col-md-12 col-sm-12">
              <a target="_blank" class="col-md-8 CollLink" href="https://www.scientificgames.com/">Scientific Games</a>
              <span class="col-md-4 years">August 2019 - September 2021</span>
            </div>
            <div style="margin-right: 0px; margin-left: 0px" class="row col-md-12 col-sm-12">
              <span class="col-md-8 boldText">Software Engineer</span>
              <span class="col-md-4 years">Chennai</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="work" class="row RCourse">
      <div class="col-md-12 col-sm-12 WorkDetaisl">
        <div class="col-md-3 col-sm-2"></div>
        <div class="col-md-7 col-sm-10" ngsRevealSet [ngsSelector]="'.item'">
          <div class="item ContentPara">
            <ul class="listCourse" ngsRevealSet [ngsSelector]="'.item'">

              <li class="item">
                Led a team in developing UI components and NgRx store
                functionalities for the iVIEW® 4 Content project using Angular, React, HTML,
                SCSS, Redux and NgRx.
              </li>

              <li class="item">
                Implemented and tested web design and development principles to build stable, responsive, and reusable
                software in an agile environment.
              </li>
              <li class="item">
                Low level design, monitored software performance, troubleshoot issues, and provide guidance and support
                to
                clients.
              </li>
              <li class="item">
                Identified and developed innovative ideas and proof of concepts based on project requirements.
              </li>
              <li class="item">
                Collaborated closely with the team throughout all project phases, maintaining an organized workflow
                using
                tools like JIRA and Perforce.
              </li>

            </ul>
          </div>
          <span class="skill item">Angular 8, 9</span>
          <span class="skill item">React</span>
          <span class="skill item">Typescript</span>
          <span class="skill item">HTML</span>
          <span class="skill item">SASS, CSS, Bootstrap</span>
          <span class="skill item">Angular Material Design</span>
          <span class="skill item">Javascript</span>
          <span class="skill item">Redux</span>
          <span class="skill item">Ngrx</span>
          <span class="skill item">SVG</span>
          <span class="skill edu item">DOR's</span>
          <span class="skill edu item">Low level designing</span>
        </div>
      </div>
    </div>

    <div class="row rowOverR">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <div onMouseOver="this.className= 'collegeDetailsOut'" onMouseOut="this.className= 'collegeDetails'" style="
              line-height: 34px;
              font-size: 19px;
              font-family: 'Karla', sans-serif;
              letter-spacing: 0.1em;
              filter: grayscale(100%);
            ">
          <div class="col-md-1 hidden-sm hidden-xs" ngsRevealSet [ngsSelector]="'.item'">
            <img class="item" src="../../assets/img/dxc.png" />
          </div>
          <div class="row col-md-11 col-sm-12">
            <div class="row rowOverR col-md-12 col-sm-12">
              <a target="_blank" class="col-md-8 CollLink" href="http://www.dxc.technology">DXC Technology</a>
              <span class="col-md-4 years">April 2017 - August 2019</span>
            </div>
            <div style="margin-right: 0px; margin-left: 0px" class="row col-md-12 col-sm-12">
              <span class="col-md-8 boldText">Full Stack Developer</span>
              <span class="col-md-4 years">Chennai</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="work" class="row RCourse">
      <div class="col-md-12 col-sm-12 WorkDetaisl">
        <div class="col-md-3 col-sm-2"></div>
        <div class="col-md-7 col-sm-10" ngsRevealSet [ngsSelector]="'.item'">
          <div class="item ContentPara">
            <ul class="listCourse" ngsRevealSet [ngsSelector]="'.item'">
              <li class="item">
                Developed, tested, and implemented new software programs in an agile environment.
              </li>
              <li class="item">
                Enhanced and maintained software to ensure optimal functionality and performance.
              </li>
              <li class="item">
                Provided comprehensive support to internal customers, resolving issues efficiently.
              </li>
              <li class="item">
                Collaborated cross-functionally with data scientists, business users, project managers, and engineers to
                deliver elegant solutions.
              </li>
              <li class="item">
                Compiled timely, comprehensive, and accurate documentation and reports.
              </li>

            </ul>
          </div>
          <span class="skill item">Angular 2,4,6</span>
          <span class="skill item">Typescript</span>
          <span class="skill item">HTML</span>
          <span class="skill item">CSS, Bootstrap</span>
          <span class="skill item">Angular Material Design</span>
          <span class="skill item">.net</span>
          <span class="skill item">Caché DB</span>
          <span class="skill edu item">Adobe Photoshop</span>
        </div>
      </div>
    </div>

    <div class="row rowOverR">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <p class="PEduSty">Education</p>
        <div onMouseOver="this.className= 'collegeDetailsOut'" onMouseOut="this.className= 'collegeDetails'" style="
              line-height: 34px;
              font-size: 19px;
              font-family: 'Karla', sans-serif;
              letter-spacing: 0.1em;
              filter: grayscale(100%);
            ">
          <div class="col-md-1 hidden-sm hidden-xs" ngsRevealSet [ngsSelector]="'.item'">
            <img class="item" src="../../assets/img/pit.png" />
          </div>
          <div class="row col-md-11 col-sm-12">
            <div class="row col-md-12 col-sm-12 rowOverR">
              <a target="_blank" class="col-md-8 CollLink" href="http://www.pit.ac.in/">Panimalar Institute of
                Technology</a>
              <span class="col-md-4 years">2012 - 2016</span>
            </div>
            <div class="row rowOverR col-md-12 col-sm-12">
              <span class="col-md-8 boldText">Computer Science, B.E.</span>
              <span class="col-md-4 years">Chennai</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row RCourse">
      <div class="col-md-12 col-sm-12 cContent">Relevant Coursework:</div>
      <div class="col-md-12 col-sm-12 CCourseWork">
        <div class="col-md-3 col-sm-2"></div>
        <div class="col-md-7 col-sm-10">
          <ul class="listCourse" ngsRevealSet [ngsSelector]="'.item'">
            <li class="item">Fundamentals of Computer Programming</li>
            <li class="item">Object-Oriented Programming</li>
            <li class="item">Principles of Software Development</li>
            <li class="item">Design and Analysis of Algorithms</li>
            <li class="item">Basic Organization of Computer Systems</li>
            <li class="item">Operating Systems</li>
            <li class="item">Database Management</li>
            <li class="item">Advanced Web Technologies</li>
            <li class="item">Digital Principles and Systems Design</li>
            <li class="item">Data Structures</li>
            <li class="item">Theory of Computation</li>
            <li class="item">Computer Networks</li>
            <li class="item">Computer Graphics</li>
            <li class="item">Software Quality Assurance</li>
            <li class="item">Engineering Mathematics</li>
          </ul>
          <div ngsRevealSet [ngsSelector]="'.item'">
            <span class="skill item">c</span>
            <span class="skill item">c++</span>
            <span class="skill item">java</span>
            <span class="skill item">Html</span>
            <span class="skill item">css</span>
            <span class="skill item">js</span>
            <span class="skill item">bootstrap</span>
            <span class="skill item">sql</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row rowOverR">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <p class="PEduSty"></p>
        <div onMouseOver="this.className= 'collegeDetailsOut'" onMouseOut="this.className= 'collegeDetails'" style="
              line-height: 34px;
              font-size: 19px;
              font-family: 'Karla', sans-serif;
              letter-spacing: 0.1em;
              filter: grayscale(100%);
            ">
          <div class="col-md-1 hidden-sm hidden-xs" ngsRevealSet [ngsSelector]="'.item'">
            <img class="item" src="../../assets/img/srv.png" />
          </div>
          <div class="row col-md-11 col-sm-12">
            <div class="row col-md-12 col-sm-12 rowOverR">
              <a target="_blank" class="col-md-8 CollLink" href="http://www.srvacademy.org/hitechboys/">SRV Boys</a>
              <span class="col-md-4 years">2010 - 2012</span>
            </div>
            <div class="row rowOverR col-md-12 col-sm-12">
              <span class="col-md-8 boldText">Higher Secondary</span>
              <span class="col-md-4 years">Namakkal</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row RCourse">
      <div class="col-md-12 col-sm-12 CCourseWork">
        <div class="col-md-3 col-sm-2"></div>
        <div class="col-md-7 col-sm-10" ngsRevealSet [ngsSelector]="'.item'">
          <div class="item ContentPara">
            Higher Secondary Examination (12th board examination) is a
            centralized examination for class 12 students in India. HSC is an
            equivalent to GCE A Level in England.
          </div>
          <span class="skill edu item">Tamil Nadu State board</span>
          <span class="skill item">Biology</span>
          <span class="skill item">Mathematics</span>
          <span class="skill item">Physics</span>
          <span class="skill item">Chemistry</span>
          <span class="skill item">Tamil, English</span>
        </div>
      </div>
    </div>
  </div>
  <div id="résumé" class="WorkBgCol">
    <p class="PResCol">work + fun</p>

    <div class="row rowOverR">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <p class="PEduWork">Blogs</p>
      </div>
      <div class="col-md-5">
        <div class="polaroid cursor" routerLink="/dialog-blog">
          <img src="../../assets/img/Dialog-Blog.jpg" alt="blog-1" style="width: 100%" />
          <div class="imgThumb">
            Build a angular dialog with css and ng-content
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="polaroid cursor" routerLink="/gmap-blog">
          <img src="../../assets/img/GMap.jpg" alt="blog-1" style="width: 100%" />
          <div class="imgThumb">Exploring features in Google maps API</div>
        </div>
      </div>
      <!--<div class="col-md-1"></div>
      <div class="col-md-5">
        <div class="polaroid">
          <img src="../../assets/img/Dialog-Blog.jpg" alt="kudos" style="width:100%">
          <div class="imgThumb">
            Build a angular dialog with css and ng-content
          </div>
        </div>
      </div>-->
    </div>

    <div class="row rowOverR marginTop60">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <p class="PEduWork">Laboratory Information Management (LIMS)</p>
        <div class="WorkLIMS" ngsRevealSet [ngsSelector]="'.item'">
          <span class="item">
            DXC's Laboratory Information Management System (LIMS) is a
            next-generation solution that helps pathology laboratories meet
            the evolving demands of the modern pathology service.
          </span>
        </div>
        <div ngsRevealSet [ngsSelector]="'.item'">
          <div class="margin2 WorkDes item">Role : Developer.</div>
          <div class="margin2 WorkDes item">Team size : 15.</div>
          <div class="margin2 WorkDes item">
            Skills used : Angular 5, Web API(.net), Caché, CSS, Bootstrap,
            Material design.
          </div>
        </div>
        <ul class="workList" ngsRevealSet [ngsSelector]="'.item'">
          <li class="item">
            Designed and developed UI components and functionalities in Angular, including version migrations and UI/UX
            improvements.
          </li>
          <li class="item">
            Retrieved and saved data from databases, publishing it through web APIs for front-end consumption.
          </li>
          <li class="item">
            Architected functionalities, and designed and implemented algorithms for efficient performance and
            maintainability.
          </li>
          <li class="item">
            Collaborated with the development manager, team lead, and others to plan design and development efforts,
            providing realistic estimates for the delivery schedule.
          </li>
        </ul>

        <div class="col-md-12 col-sm-12" ngsRevealSet [ngsSelector]="'.item'">
          <div class="col-md-5 col-sm-5 polaroid item margin30">
            <img src="../../assets/img/Kudos.JPG" alt="kudos" style="width: 100%" />
            <div class="imgThumb">
              Recognized for consistent quality and timely delivery since inception.
            </div>

          </div>
          <div class="col-md-5 col-sm-5 polaroid item margin30">
            <img src="../../assets/img/spot.jpg" alt="kudos" style="width: 100%" />
            <div class="imgThumb">
              Recognized for delivering significant value to both the organization and the product.
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row rowOverR marginTop60">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <p class="PEduWork">iVIEW® 4</p>
        <div class="WorkLIMS" ngsRevealSet [ngsSelector]="'.item'">
          <span class="item">
            SG's iVIEW 4, a next generation player interface, showcases
            flexibility across the spectrum for enhancing player experiences
            and facilitating customer service and employee duties. The iVIEW 4
            interface displays dynamic marketing animations and video with
            instant response through a state-of-the-art multi-touch display.
          </span>
        </div>
        <div ngsRevealSet [ngsSelector]="'.item'">
          <div class="margin2 WorkDes item">Role : Developer.</div>
          <div class="margin2 WorkDes item">Team size : 30.</div>
          <div class="margin2 WorkDes item">
            Skills used : Angular 9, React, Redux, ngrx, Typescript/JS, SASS,
            Material design.
          </div>
        </div>
        <ul class="workList" ngsRevealSet [ngsSelector]="'.item'">
          <li class="item">
            Developed and Optimized UI Components: Write and refine UI code to create, enhanced, and maintained UI
            components, focusing on performance optimization, reusable CSS, cross-browser compatibility, and
            multi-resolution display support.
          </li>
          <li class="item">
            Managed State using NgRx: Implemented and updated NgRx store functionalities, including actions, reducers,
            selectors, and effects, ensuring efficient state management and seamless UI integration.
          </li>
          <li class="item">
            Collaborated, Reviewed, Participated in daily stand-ups, design discussions, and sprint planning meetings
            using JIRA. Reviewed team members' code, provided constructive feedback, and ensured adherence to coding
            standards and best practices.
          </li>
          <li class="item">
            Testing and Debugging: Conducted unit tests using Jasmine and end-to-end tests using Cypress or Selenium.
            Debuged and resolved issues to maintain high code quality and a consistent user experience across various
            browsers and devices.
          </li>
        </ul>


        <div class="col-md-12 col-sm-12 flex-centre" ngsRevealSet [ngsSelector]="'.item'">
          <div class="col-md-5 col-sm-5 polaroid item margin30">
            <img src="../../assets/img/star_white.png" alt="kudos" />
            <div class="imgThumb">
              Appreciated for introducing NgRx store and improving app performance.
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="row rowOverR marginTop60">
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-8 col-sm-10">
        <p class="PEduWork">Webex</p>
        <div class="WorkLIMS" ngsRevealSet [ngsSelector]="'.item'">
          <span class="item">
            The Wholesale Route-to-Market (RTM) strategically brings Webex to SMBs through global Service Providers.
            Supported by Cisco's tech and collaboration expertise, it features a new operations model, commercials, and
            partner programs.

            Wholesale offers operations APIs and Partner Portal innovations for efficient high-volume transactions. The
            commercial strategy enables BroadWorks users to transition to Webex with fixed, per-user monthly packaging
            and simplified billing.
          </span>
        </div>
        <div ngsRevealSet [ngsSelector]="'.item'">
          <div class="margin2 WorkDes item">Role : Developer.</div>
          <div class="margin2 WorkDes item">Team size : 10.</div>
          <div class="margin2 WorkDes item">
            Skills used : Angular 11, 12, 15, React, Typescript, HTML, SASS, CSS, Javascript, Ngrx, Cypress, Selenium,
            Jest,
            Jasmine, Java, Team Management, GIT
          </div>
        </div>
        <ul class="workList" ngsRevealSet [ngsSelector]="'.item'">
          <li class="item">
            Develop and Enhance UI: Design, implement, and refine the Webex Wholesale UI using Angular, HTML, SCSS,
            NgRx, and Java, ensuring high performance and a seamless user experience.
          </li>
          <li class="item">
            Provide Technical Guidance: Offer strategic technical advice and support, foster cross-team collaboration,
            Involve in designing
            and drive innovation while ensuring effective planning and maintaining the team's work-life balance.
          </li>
          <li class="item">
            Testing and Quality Assurance: Conduct unit testing with Jest, perform end-to-end testing with Cypress, and
            manage version control using Git to ensure code quality and stability.
          </li>
          <li class="item">
            Participate in Agile Processes: Engage in Definition of Ready (DOR) and SPIKE meetings to support efficient
            planning and adherence to agile development practices, ensuring timely and effective project delivery.
          </li>
        </ul>


        <div class="col-md-12 col-sm-12" ngsRevealSet [ngsSelector]="'.item'">
          <div class="col-md-5 col-sm-5 polaroid item margin30">
            <img src="../../assets/img/playtimetop.jpeg" alt="kudos" style="width: 80%" />
            <div class="imgThumb">
              Ranked in the top 10% for global Webex playtime.
            </div>
          </div>
          <div class="col-md-5 col-sm-5 polaroid item margin30">
            <img src="../../assets/img/chn-innovation.jpeg" alt="kudos" style="width: 100%" />
            <div class="imgThumb">
              Runner-Up for Chennai Innovation.
            </div>
          </div>
        </div>
        <app-carousel></app-carousel>

      </div>
    </div>



  </div>
  <div class="FooterSty">
    <div class="row col-md-12">
      <div class="col-md-1"></div>
      <span class="col-md-2 col-sm-2 padtop2">
        <span class="col-md-1 rotate180">&copy; </span>
        2024 Sibiraj Rajendran
      </span>
    </div>
  </div>
</body>
<div class="contic">
  <ul class="contactsIcons">
    <li>
      <a target="_blank" href="https://github.com/sibirajr">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>
          <i class="fab fa-github" aria-hidden="true"></i>
        </span>
      </a>
    </li>
    <li>
      <a target="_blank" href="https://www.linkedin.com/in/sibiraj-rajendran">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>
          <i class="fab fa-linkedin" aria-hidden="true"></i>
        </span>
      </a>
    </li>
    <li>
      <a target="_blank" href="https://www.npmjs.com/~sibirajrajendran">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>
          <i class="fab fa-npm" aria-hidden="true"></i>
        </span>
      </a>
    </li>
    <li>
      <a target="_blank" href="https://www.facebook.com/sibiraj1994">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>
          <i class="fab fa-facebook-square" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
</div>

</html>
<!-- <dialog_blog> </dialog_blog>
<g-map-blog></g-map-blog> -->