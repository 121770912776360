import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteHomeComponent } from './site-home/site-home.component';
import { DialogBlogComponent } from './dialog-blog/dialog-blog.component';
import { GmapBlogComponent } from './gmap-blog/gmap-blog.component';


const routes: Routes = [
  { path: '', component: SiteHomeComponent },
  { path: 'home', component: SiteHomeComponent },
  { path: 'dialog-blog', component: DialogBlogComponent },
  { path: 'gmap-blog', component: GmapBlogComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
